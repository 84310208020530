import gql from 'graphql-tag'

export const GetItemGroupRecipes = gql`
query ($itemGroupId: ID!, $currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  itemGroupRecipes (itemGroupId: $itemGroupId, currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      adminShop {
        name
        code
      }
      mainImage
      mainImageSet {
        smImage
        mdSqImage
      }
      title
      cookName
      publishedStatus
      publishedAt
      customUrl
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}`

export const GetRecipes = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  recipes (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      adminShop {
        name
      }
      mainImageSet {
        smSqImage
      }
      title
      frontUrl
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}`

export const GetRecipe = gql`
query($recipeId: ID!) {
  recipe(recipeId: $recipeId) {
    id
    adminShop {
      name
    }
    mainImage
    mainImageSet {
      smImage
    }
    eyeCatchImage
    title
    subTitle
    description
    cookName
    cookingTime
    serving
    servingSize
    calories
    fatContent
    author
    publishedStatus
    publishedAt
    customUrl
    frontUrl
    metaTitle
    metaDescription
    recipeIngredients {
      id
      ingredientName
      quantity
    }
    recipeSteps {
      id
      image
      title
      description
    }
    recipeNotes {
      id
      title
      description
    }
    recipeItemGroups {
      id
      itemGroup {
        id
        adminShop {
          name
        }
        code
        name
        publishedStatus
        saleStatus
        listImageUrl
      }
    }
  }
}`

export const GetRecipesForIndex = gql`
query($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  recipes(currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      title
      frontUrl
      simpleFormatPublishedAt
      publishedAt
      eyeCatchImage
      eyeCatchImageSet {
        xsImage
      }
      mainImage
      mainImageSet {
        xsImage
      }
      adminShop {
        name
        code
        logoImageSet {
          xsSqImage
        }
      }
    }
    pageInfo {
      totalCount
      perPage
      currentPage
    }
  }
}
`