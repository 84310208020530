<template lang="pug">
.faq-item
  .head
    button.button(@click="toggle")
      .question
        .q Q
        .text.word-pre {{ faq.question }}
        .iconwrap
          IconArrowDown(:class="{ 'is-open': isOpen }")
  .body(:class="{ 'is-open': isOpen }")
    .a A
    .wrapper
      template(v-for="body in faq.qaItemBodies")
        p(v-if="body.contentType === 'title_content'")  {{body.title}}
        VueMarkdown.markdown(v-if="body.contentType === 'text_content'" :source="body.description")
        img.img-responsive(v-if="body.contentType === 'image_content'" :src="body.image")
    p.text {{ faq.text }}
</template>

<script>
import VueMarkdown from 'vue-markdown'
import IconArrowDown from "../../../../packs/components/v1/icon/IconArrowDown.vue";
export default {
  name: "FaqItem",
  components: {
    IconArrowDown,
    VueMarkdown
  },
  props: {
    faq: {
      type: Object,
      required: true,
      default: () => {},
    },
    faqOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.faqOpen,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.$emit('faq-open', this.isOpen)
    },
  },
};
</script>

<style lang="scss">
@import "../../../../../assets/stylesheets/v1/styles";
.faq-item {
  width: 100%;

  > .head {
    > .button {
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      width: 100%;
      text-align: left;
      background-color: $ivory-100;
      @include padding-top(xs);
      @include padding-right(xxs);
      @include padding-bottom(xxs);
      @include padding-left(xs);
      font-family: $font-family-sans;
      @include font-size(sm);
      line-height: $line-height-snug;
      font-weight: $font-weight-regular;
      color: $black-400;

      > .question {
        display: flex;

        > .q {
          @include margin-right(sm);
        }

        > .iconwrap {
          margin-left: auto;

          > .icon-base {
            transform: rotate(0deg);
          }

          > .is-open {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  > .body {
    display: none;
    font-family: $font-family-sans;
    @include font-size(sm);
    line-height: $line-height-tight;
    font-weight: $font-weight-regular;
    color: $black-400;
    @include padding-top(xs);
    @include padding-right(xs);
    @include padding-bottom(xs);
    @include padding-left(xs);
    border-right: 2px solid $ivory-100;
    border-bottom: 2px solid $ivory-100;
    border-left: 2px solid $ivory-100;

    > .a {
      @include margin-right(sm);
    }
    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      img {
        max-width: 100%;
        height: auto;
      }
      .markdown {
        p:not(:first-child) {
          @include margin-top(xs);
        }
      }
    }
  }

  > .is-open {
    display: flex;
  }
}
</style>
