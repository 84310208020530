<template lang="pug">
  .container
    .row
      .col-xs-12
        h1.heading-3.text-center.my-md 定期購入管理
        .contents-wrapper(v-if="subscriptionOrderSummary.id")
          .contents
            h2 出荷履歴
            .form-wrapper
              .item-wrapper
                .item(v-for="subscriptionOrder of subscriptionOrders" :index="subscriptionOrder.id" )
                  template(v-if="subscriptionOrder.order" )
                    .title {{subscriptionOrder.formatDeliveryOn}}
                    .body
                      p 受注番号：{{subscriptionOrder.order.code}}
                      p {{subscriptionOrder.order.currentOrderSummary.orderStatusLabel}}

          ButtonBase(:to="{name: 'mypage-subscription-orders-show', params: {code: subscriptionOrderSummary.code}}" variant="normal") 定期購入一覧へ戻る
</template>

<script>
import ButtonBase from '../../components/v1/button/ButtonBase'
import {
  GetSubscriptionOrderSummaryForShippingHistory
} from "../../graphqls/SubscriptionOrderSummaries";
import GlobalMixin from "../../mixin/GlobalMixin";

export default {
  name: "SubscriptionOrdersShipping",
  mixins: [GlobalMixin],
  components: {
    ButtonBase,
  },
  data() {
    return {
      subscriptionOrderSummary: {
        id: "",
        code: "",
        subscriptionOrders: []
      }
    }
  },
  computed: {
    subscriptionOrders() {
      return this.subscriptionOrderSummary?.subscriptionOrders || []
    }
  },
  metaInfo() {
    return {
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: this.$_itemListElement
        }
      }]
    }
  },
  async mounted() {
    this.loadingStart();
    await this.getSubscriptionOrderSummary();
    this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/mypage/',
          name: 'マイページ',
        },
        {
          path: '/mypage/subscriptionorders/',
          name: '定期購入管理',
        },
        {
          path: `/mypage/subscriptionorders/${this.subscriptionOrderSummary.code}`,
          name: this.subscriptionOrderSummary.code,
        },
        {
          path: `/mypage/subscriptionorders/${this.subscriptionOrderSummary.code}/shipping`,
          name: '出荷履歴',
        },
      ]})
    this.loadingStop();
  },
  methods: {
    async getSubscriptionOrderSummary() {
      const { data } = await this.$apollo
          .query({
            query: GetSubscriptionOrderSummaryForShippingHistory,
            variables: {
              subscriptionOrderSummaryCode: this.$route.params.code,
              subscriptionOrderSummaryId: null
            },
            client: 'apiClient'
          })
          .catch(error => {
            this.loadingStop()
            return;
          });
      this.subscriptionOrderSummary = data.subscriptionOrderSummary
    },
  }


}
</script>

<style scoped lang="scss">
@import "../../../../assets/stylesheets/v1/styles";
.container {
  margin: auto;
  line-height: $line-height-snug;
  > .row {
    > .col-xs-12 {
      > .contents-wrapper {
          max-width: 800px;
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 72px;
          align-self: stretch;
        > .contents {
            width: 100%;
          > h2 {
            text-align: center;
            margin: 2rem auto;
          }
          > .form-wrapper {
            font-size: 14px;
            background: $ivory-100;
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            @include mq-down(){
              padding: 24px 8px;
            }
            > .item-wrapper {
              display: flex;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
              background: $white;
              > .bold {
                font-weight: bold;
              }
              > .warning{
                color: $red-300;
              }
              > a.link {
                color: $olive-400;
                text-decoration-line: underline;
              }
              > .item {
                > .title {
                  margin-bottom: 4px;
                  font-weight: bold;
                }
              }
            }
            > .text-center {
              width: 100%;
              margin-top: 12px;
            }
            > p {
              margin-top: 12px;
            }
            > a.link {
              color: $olive-400;
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }
}
</style>