<template lang="pug">
  .item-card
    ListItemSkeleton(v-if="showSkeleton")
    a.card(v-else @click="sendGAEvent" :href="itemGroup.detailPagePath" :class="{'-archive': archive}")
      .card-image
        .ribbon-rank(v-if="rank > 0")
          span(:class="`-rank${rank}`") {{ rank }}
        .ribbon-sale(v-if="isSale")
          span {{ ribbonSaleName }}
        //- img.thumbnail(:src="itemGroup.listImageOrItemGroupImageSet.mdSqImage" :alt="itemGroup.name" width="100%" height="auto")
        LazyImg.thumbnail(:src="itemGroup.listImageOrItemGroupImageSet.smSqImage" :alt="itemGroup.name")
        .status(v-if="itemGroup.afterPreSale")
          span 予約終了
        .status(v-else-if="preRelease || soldOut || itemGroup.beforePreSale")
          span 準備中
      .card-body
        .card-item
          ul.labels
            li.label-item(v-if="itemGroup.isNew") NEW
            li.label-item(v-if="isFreeShipping") 送料無料
            li.label-item(v-if="itemGroup.isPreSale") 予約商品
            li.label-item(v-if="itemGroup.isNormal") 常温
            li.label-item(v-if="itemGroup.isCold") 冷蔵
            li.label-item(v-if="itemGroup.isFreeze") 冷凍
            li.label-item(v-if="itemGroup.isSecondGradeItem") 訳あり
            li.label-item(v-if="isGiftPossible") ギフト
            li.label-item(v-if="isSale") {{saleName ? saleName : 'SALE'}}
            li.label-item(v-if="hasSubscriptionItem") 定期購入可
            li.label-item(v-if="hasOnetimeItem") 初回限定
            li.label-item(v-if="fewInStock") 残り{{totalSalableStock}}個
            li.label-item(v-if="hasFreeShippingRegions") 送料無料あり
            li.label-item(v-if="hasPostDeliveryItem") メール便対応
            li.label-item(v-if="hasCompactDelivery") コンパクト便対応
            //li.label-item(v-for="icon in itemGroup.icons") {{ icon }}
        .card-item
          a.shop-link(:href="`/shop/${itemGroup.adminShop.code}/`" @click="sendGAEventToShopTop")
            p.shop-name {{ itemGroup.adminShop.name }}
          p.item-name {{ itemGroup.name }}
          //- p.sku-size(v-if="displaySize") {{`(${displaySize})`}}
        .card-item
          .regular-price(:class="{'-sale': isSale}")
            | {{ properPriceInTax | numberWithDelimiter }}
            small.unit 円
            template(v-if="isRangeProperPrice")
              | ~{{ maxProperPriceInTax | numberWithDelimiter }}
              small.unit 円
          .sale-name(v-if="isSale")
            template(v-if="offSaleEndAt") {{ offSaleEndAt | moment('M月D日') }}までの
            template(v-if="saleName") {{ saleName }}価格
            template(v-else) セール価格
          .sale-price(v-if="isSale")
            | {{salePriceInTax | numberWithDelimiter }}
            small.unit 円
            template(v-if="isRangeSalePrice")
              | ~{{ maxSalePriceInTax | numberWithDelimiter }}
              small.unit 円
        .card-item(v-if="itemGroup.publishedNotice || itemGroup.adminShop.displayShippingWithinYearLastAt || itemGroup.isPreSale")
          .notice-wrapper
            .notice
              template(v-if="itemGroup.adminShop.displayShippingWithinYearLastAt")
                | {{ itemGroup.adminShop.displayShippingWithinYearLastAt }}
                br
              template(v-if="itemGroup.publishedNotice") {{ itemGroup.notice }}
              template(v-if="itemGroup.isPreSale" )
                p 予約期間：{{itemGroup.formatPreSaleStartAt}}〜{{itemGroup.formatPreSaleEndAt}}
                p {{itemGroup.formatScheduledShippingStartAt}}頃より順次発送
        .card-item(v-if="reviewNum > 0")
          .review
            img.star(:src="reviewsRatingStarsUrl" width="auto" height="12px")
            span.count ({{ reviewNum }})
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import LazyImg from '../../image/LazyImg'
  import ListItemSkeleton from '../../skeleton/ListItemSkeleton'

  export default {
    name: 'ItemCardForApi',
    mixins: [GlobalMixin],
    components: {
      LazyImg,
      ListItemSkeleton
    },
    props: {
      itemGroup: {
        type: Object,
        required: true,
        default: function () {
          return {}
        }
      },
      archive: {
        type: Boolean,
        required: false,
        default: false
      },
      rank: {
        type: Number,
        required: false,
        default: 0
      },
      eventCategory: {
        type: String,
        required: false,
        default: ''
      },
      pageContents: {
        type: String,
        required: false,
        default: ''
      },
    },
    computed: {
      items() {
        return this.itemGroup?.openItems || []
      },
      reviews() {
        return this.itemGroup?.publishedReviews || []
      },
      showSkeleton() {
        return !this.itemGroup.code
      },
      isSale() {
        for(let item of this.items) {
          if (item.isSale) return true
        }
        return false
      },
      saleName() {
        let result = ''
        for(let item of this.items) {
          if (item.isSale) {
            result = item.saleName
            break;
          }
        }
        return result
      },
      ribbonSaleName() {
        let result = ''
        for(let item of this.items) {
          if (item.isSale) {
            result = item.ribbonSaleName
            break;
          }
        }
        return result
      },
      offSaleEndAt() {
        let result = ''
        for(let item of this.items) {
          if (item.isSale || item.offSaleEndAt) {
            result = item.offSaleEndAt
            break;
          }
        }
        return result
      },
      nowOnSale() {
        return this.itemGroup?.isNowOnSale && this.items.some(item => item.isNowOnSale)
      },
      preRelease() {
        return !this.nowOnSale
      },
      soldOut() {
        return this.totalSalableStock === 0
      },
      totalSalableStock() {
        let result = 0
        for (let item of this.items) {
          if (item.isNowOnSale) {
            result = result + item.salableStock
          }
        }
        return result
      },
      fewInStock() {
        return 0 < this.totalSalableStock && this.totalSalableStock < 10
      },
      displaySize() {
        let result = []
        for(let item of this.items) {
          if (result.indexOf(item.size) === -1) {
            result.push(item.size)
          }
        }
        return result.join('/')
      },
      properPriceInTax() {
        let result = null
        for(let item of this.items) {
          if (!result || result > item.properPriceInTax) {
            result = item.properPriceInTax
          }
        }
        return result
      },
      maxProperPriceInTax() {
        let result = 0
        if (this.items.length > 1 ) {
          for(let item of this.items) {
            if (result < item.properPriceInTax) {
              result = item.properPriceInTax
            }
          }
        }
        return result
      },
      isRangeProperPrice() {
        return this.maxProperPriceInTax > 0
      },
      salePriceInTax() {
        return this.salePrices[0]
      },
      maxSalePriceInTax() {
        return this.salePrices[-1]
      },
      isRangeSalePrice() {
        return this.salePrices.length > 1
      },
      salePrices() {
        let result = []
        for(let item of this.items) {
          if (item.isSale &&  item.salePriceInTax > 0 && result.indexOf(item.salePriceInTax) === -1) {
            result.push(item.salePriceInTax)
          }
        }
        return result.sort((a, b) => a - b);
      },
      hasFreeShippingRegions() {
        for(let item of this.items) {
          if (item && item.itemFreeShippingRegions.length > 0) {
            return true
          }
        }
        return false
      },
      hasSubscriptionItem() {
        return this.items.some(item => item.isSubscriptionItem)
      },
      hasOnetimeItem() {
        return this.items.some(item => item.isOnetimeItem)
      },
      hasPostDeliveryItem() {
        return this.items.some(item => item.itemDeliveryMethod?.isPostDelivery)
      },
      hasCompactDelivery() {
        return this.items.some(item => item.itemDeliveryMethod?.isCompactDelivery)
      },
      reviewNum() {
        return this.reviews.length
      },
      reviewsRatingStarsUrl() {
        if (this.reviewNum === 0) return null;
        let sumReview = 0
        this.reviews.forEach(review => sumReview = sumReview + review.stars)
        let starCount = Math.round(sumReview / this.reviewNum)
        return `https://cdn.taberutokurasuto.com/image/common/star/star${starCount}.png`
      },
      isGiftPossible() {
        return this.itemGroup.isNoshiPossible ||
            this.itemGroup.isWrappingPossible ||
            this.itemGroup.isMessageCardPossible
      },
      delivery() {
        return this.itemGroup?.adminShop?.delivery
      },
      isFreeShipping() {
        if (this.itemGroup?.isForceFreeShipping) return true

        if (this.delivery?.freeShippingStatus === 'free_shipping_on') {
          for(let item of this.items) {
            if (item.sellingPrice > this.delivery?.freeShippingPrice) return true
          }
        }
        return false
      },
    },
    methods: {
      sendGAEvent: function () {
        this.$_gaSendEvent('商品詳細へ遷移', this.eventCategory, `${this.itemGroup.adminShop.name} ${this.itemGroup.name}`)
        this.$gtag.event('select_item', {
          items: [{
            item_id: this.itemGroup.id,
            item_name: `${this.itemGroup.adminShop.name} ${this.itemGroup.name}`,
            affiliation: this.itemGroup.adminShop.code,
            item_brand: this.itemGroup.adminShop.name,
          }],
          page_contents: this.pageContents,
        })
      },
      sendGAEventToShopTop() {
        this.$_gaSendEvent('ショップトップへ遷移', this.eventCategory, `${this.itemGroup.adminShop.name} ${this.itemGroup.name}`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .card {
    display: flex;
    align-items: flex-start;
    color: $black-400;
    &:hover {
      color: $olive-400;
    }
    cursor: pointer;
    text-decoration: none;

    &.-archive {
      flex-direction: column;
      > .card-image {
        width: 100%;
      }
      > .card-body {
        width: 100%;
        padding-left: 0;
        > .card-item {
          &:first-child {
            @include margin-top(xxxs);
          }
        }
      }
    }

    @include mq-up() {
      flex-direction: column;
      padding-left: 30px;
      position: relative;
    }

    > .card-image {
      width: 30%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq-up() {
        width: 100%;
      }

      > .ribbon-rank {
        display: block;
        width: 35px;
        height: 30px;
        overflow: hidden;
        position: absolute;
        top: -6px;
        right: 3px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 30px;
          z-index: -1;
          display: block;
          border: 5px solid $black-300;
          border-top-color: transparent;
          border-right-color: transparent;
        }

        > span {
          display: block;
          width: 30px;
          height: 17px;
          background-color: $black-300;
          &.-rank1 {
            background-color: #b97518;
          }
          &.-rank2 {
            background-color: #8f796f;
          }
          &.-rank3 {
            background-color: #6c380e;
          }

          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          font-size: 12px;
          line-height: 17px;
          color: $white;
          box-shadow: 0 5px 10px rgba(0,0,0,.1);
          text-shadow: 0 1px 1px rgba(0,0,0,.2);
          text-align: center;
        }
      }

      > .ribbon-sale {
        width: 60px;
        height: 60px;
        @include mq-up() {
          width: 75px;
          height: 75px;
        }
        position: absolute;
        left: -5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;

        > span {
          position: absolute;
          top: 13px;
          left: -22px;
          display: block;
          width: 90px;
          background: $olive-400;
          box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);

          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(xxs);
          line-height: 20px;
          color: $white;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;

          @include mq-up() {
            width: 100px;
            top: 19px;
            left: -21px;
          }

          &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 3px;
            @include mq-up() {
              left: 0px;
            }
            z-index: -1;
            border-left: 3px solid $black-300;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $black-300;
          }

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            right: 3px;
            @include mq-up() {
              right: 0px;
            }
            z-index: -1;
            border-left: 3px solid transparent;
            border-right: 3px solid $black-300;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $black-300;
          }
        }
      }

      > .thumbnail {
        width: 100%;
        height: auto;
      }

      > .status {
        position: absolute;
        // top: 50%;
        // left: 50%;
        // -ms-transform: translate(-50%, -50%);
        // -webkit-transform: translate(-50%, -50%);
        // transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.8);
        padding: 1em;
        font-family: $font-family-sans;
        font-weight: $font-weight-bold;
        font-style: $font-style-not-italic;
        @include font-size(xs);
        line-height: $line-height-snug;
        color: $black-400;
        white-space: nowrap;
      }
    }

    > .card-body {
      width: 70%;
      @include padding-left(xs);
      @include mq-up() {
        width: 100%;
        padding-left: 0;
      }

      > .card-item {
        @include margin-top(xxxs);
        &:first-child {
          @include mq-down() {
            margin-top: 0;
          }
        }
      }

      small {
        &.unit {
          @include font-size(xxs);
        }
      }

      .labels {
        display: flex;
        flex-wrap: wrap;

        > .label-item {
          display: block;
          height: 18px;
          border: $olive-400 1px solid;
          padding: 0 4px;
          margin: -1px -1px 0 0;
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          font-size: 10px;
          line-height: 16px;
          color: $olive-400;
        }
      }

      .shop-link {
        color: $black-400;
        text-decoration: none;
      }

      .shop-name {
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xs);
        line-height: $line-height-snug;
        @include mq-up() {
          position: absolute;
          top: 0;
          left: 0;
          -webkit-writing-mode: vertical-rl;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          font-feature-settings: 'palt' 0;
        }
      }

      .item-name {
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-snug;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-bottom:0px;
      }

      .sku-size {
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xs);
        line-height: $line-height-snug;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .regular-price {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-snug;
        &.-sale {
          text-decoration: line-through;
        }
      }

      .sale-name {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xxs);
        line-height: $line-height-snug;
        color: $olive-400;
      }

      .sale-price {
        font-family: $font-family-sans;
        font-weight: $font-weight-bold;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-snug;
        color: $olive-400;
      }
      .notice-wrapper {
          @include padding-top(xxs);
          @include padding-bottom(xxs);
          border-bottom: 1px solid $gray-200;
          border-top: 1px solid $gray-200;
        .notice {
          font-family: Noto Sans JP,sans-serif;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          line-height: $line-height-snug;
          @include font-size(xs);
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-family: Noto Sans JP,sans-serif;
          color: #383838;
        }
      }

      .review {
        display: inline-flex;
        align-items: center;
        > .count {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-snug;
          color: $olive-400;
          margin-left: 2px;
        }
      }
    }
  }
</style>
