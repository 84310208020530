
<template lang="pug">
a.blogCard(:href="post.frontUrl")
  .eyeCatch
    img(:src="post.eyeCatchImage" :alt="post.title")
  .blogInfo
    .nameDate
      .shopInfo(v-if="!shopInfo")
        .shopLogo
          img(:src="post.logoImage")
        .shopName {{ post.shopName }}
      .date
        p {{ formatPostDate(post.publishedAt) }}
    .title 
      p {{ post.title }}
    .description
      p {{ post.desc }}
</template>

<script>
  export default {
    name: 'BlogCard',
    props: {
      post: {
        type: Object,
        required: true,
        default() {
          return {
            frontUrl: '',
            eyeCatchImage: "https://cdn.taberutokurasuto.com/image/common/noimage_300x300.gif",
            shopName: '',
            logoImage: "https://cdn.taberutokurasuto.com/image/common/noimage_100x100.gif",
            publishedAt: '',
            title: '',
            desc: '',
          }
        },
      },
      shopInfo: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
      formatPostDate(publishedStartAt) {
        const now = new Date();
        const postDate = new Date(publishedStartAt);

        const timeDiffInMilliseconds = now - postDate;
        const timeDiffInSeconds = Math.floor(timeDiffInMilliseconds / 1000);
        const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
        const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
        const timeDiffInDays = Math.floor(timeDiffInHours / 24);

        if (timeDiffInDays < 7) {
          if (timeDiffInHours < 24) {
            return `${timeDiffInHours}時間前`;
          } else {
            return `${timeDiffInDays}日前`;
          }
        } else {
          const year = postDate.getFullYear();
          const month = String(postDate.getMonth() + 1).padStart(2, '0');
          const day = String(postDate.getDate()).padStart(2, '0');
          return `${year}/${month}/${day}`;
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
    .blogCard {
      display: flex;
      padding: 24px 40px;
      align-items: flex-start;
      gap: 32px;
      align-self: stretch;
      border-bottom: 1px solid $gray-200;
      color: $black-400;
      text-decoration-line: none;
      &:hover {
        cursor: pointer;
      }
      &:hover .title {
        color: $olive-400;
      }
      > .eyeCatch{
        width: 160px;
        height: 120px;
        > img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      > .blogInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        width: calc(100% - 192px);
        > .nameDate {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          line-height: 1;
          color: $black-200;
          gap: 8px;
          >.shopInfo {
            width: calc(100% - 80px);
            display: flex;
            align-items: center;
            gap: 4px;
            >.shopLogo {
              width: 20px;
              height: 20px;
              > img {
                width: 100%;
                height: 100%;
              }
            }
            >.shopName {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              flex: 1 0 0;
              margin: auto 0;
              font-size: 14px;
            }
          }
          >.date {
            margin: auto 0;
            color: $black-200;
            font-size: 14px;
          }
        }
        > .date {
          color: $black-200;
          font-size: 14px;
          line-height: 1;
        }
        > .title {
          font-size: 18px;
          line-height: 1.3;
          font-family: Noto Serif JP;     
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        > .description {
          font-size: 14px;
          line-height: 1.6;
          color: $black-200;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    @include mq-down() {
      .blogCard {
        padding: 16px 12px;
        gap: 12px;
        > .eyeCatch{
          width: 96px;
          height: 72px;
        }
        > .blogInfo {
          width: calc(100% - 104px);
          gap: 8px;
          > .nameDate {
            font-size: 12px;
            >.shopInfo {
              >.shopName {
                font-size: 12px;
              }
            }
            >.date {
              font-size: 12px;
            }
          }
          > .date {
            font-size: 12px;
          }
          > .title {
            font-size: 16px;
          }
          > .description {
            font-size: 12px;
          }
        }
      }
    }
</style>