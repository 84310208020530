<template lang="pug">
  .laws-show
    h1.heading-1.text-center.my-md 特定商取引法に基づく表記
    .wrapper
      .item
        h2 お問い合わせ先
        p
          | ご注文内容に関するお問い合わせにつきましては、ログイン後マイページ＞注文履歴＞「ご注文に関するお問い合わせ」よりお問い合わせください。
          br
          | 商品に関するお問い合わせは各商品ページの商品Q&Aよりお問い合わせください。
          br
          | 特商法に関するお問い合わせは「特商法に関する内容」であることを明記した上で、
          a(href="/contacts/") こちらのお問い合わせフォーム
          | からお問い合わせください。
      .item
        h2 販売者        
        p {{ adminShop.name }}
        template(v-if="adminShop.invoiceType === 'invoice_on'")
          p 適格請求書発行事業者への登録：あり
          p(v-if="adminShop.invoiceNo" ) 登録番号：{{adminShop.invoiceNo}}
        p ※ 所在地・連絡先は請求後速やかに開示します。
      .item
        h2 運営者
        p 株式会社ess
      .item
        h2 代表者
        p 岩永亮
      .item
        h2 所在地
        p 神奈川県中郡大磯町大磯705
      .item
        h2 連絡先
        p 
          | contact@taberutokurasuto.com 
          br
          | ※特商法に関わるお問い合わせである旨記載していただきご連絡をお願いいたします。
          br
          | 運営者、代表者、所在地、連絡先は「たべるとくらすと」を運営している株式会社essの情報を記載しております。{{ adminShop.name }}に関するお問い合わせや、返品などのご相談は、
          a(:href="`/shop/${adminShop.code}/contact/`") こちら
          | からお問い合わせください。
      .item
        h2 価格について
        p 商品ごとに表示
      .item
        h2 配送料について
        p 配送料は商品ページ、または注文確認画面をご確認ください。
      .item
        h2 お支払い方法
        p クレジットカード、銀行振込、代金引換（販売者によっては不可）
      .item
        h2 お支払い期限
        p クレジットカードの与信枠確保は注文時に行われます。 支払い処理は出荷完了時に行われます。 その後のお支払いはクレジットカード会社の締め日に準じます。
      .item
        h2 お届け対象地域について
        p 一部のサービスを除き、日本全国発送可能です（一部地域を除く）
      .item
        h2 商品の引き渡し時期
        p {{adminShop.lawDeliveryTime}}
      .item
        h2 ご注文のキャンセルについて
        p ご注文後のキャンセルは承っておりません。ご注文確定前に内容のご確認をお願いいたします。
      .item
        h2 商品の返品・交換について
        p 本サイトで取り扱う商品は殆どが食品となるため、返品・交換は一切承っておりません。 ただし、商品に不備がある場合、商品説明と実際の商品が異なる場合、梱包の不備により配送時に商品が破損したなどの場合は返品が可能です。
      .item
        h2 資格・免許
        p {{ adminShop.lawLicense }}
</template>

<script>
  import { GetAdminShopLaw } from "../../graphqls/AdminShops";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import CreditCards from "../../components/global/CreditCards";

  export default {
    name: "laws-show",
    mixins: [GlobalMixin],
    components: {
      CreditCards
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    data() {
      return {
        adminShop: {
          invoiceType: 'invoice_off',
          invoiceNo: '',
        },
        code: this.$route.params.shop_code
      }
    },
    mounted () {
      this.fetchQas()
      // サイド表示
      this.showSide()
      // サイドメニューのショップ情報表示
      this.fetchCurrentShop({
        shopCode: this.code
      })
    },
    methods: {
      async fetchQas() {
        this.loadingStart()
        const { data } = await this.$apollo
        .query({
          query: GetAdminShopLaw,
          variables: { adminShopCode: this.$route.params.shop_code }
        })
        this.adminShop = data.adminShop
        // パンくずリストを設定
        this.setBreadcrumbs({ breadcrumbs: [
          {
            path: '/',
            name: 'TOP',
          },
          {
            path: `/shop/${this.adminShop.code}/`,
            name: this.adminShop.name,
          },
          {
            path: `/shop/${this.adminShop.code}/law/`,
            name: '特定商取引法に基づく表記',
          },
        ]})
        this.loadingStop()
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../../../../assets/stylesheets/v1/styles";

.laws-show {
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
  font-style: $font-style-not-italic;
  line-height: $line-height-relaxed;
  color: $black-400;
  > .wrapper {
    > .item {
      margin-bottom: 24px;
      > h2 {        
        font-size: 16px;
      }
      > p {
        font-size: 14px;
        white-space: pre;
        > a {
          color: $olive-400;
        }
      }
    }
  }
}
</style>

