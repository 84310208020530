import gql from 'graphql-tag'

const ItemGroupItems = `
  id
  name
  code
  adminShop {
    code
    name
    prefectureName
  }
  listImageOrItemGroupImageSet {
    smSqImage
    mdSqImage
  }
  properPrice
  properPriceInTax
  maxProperPriceInTax
  isRangeProperPrice
  salePrice
  salePriceInTax
  maxSalePriceInTax
  isRangeSalePrice
  preRelease
  soldOut
  maxOffRate
  saleName
  offSaleEndAt
  displaySize
  publishedNotice
  notice
  reviewNum
  reviewsRatingStarsUrl
  isSale
  icons
  detailPageUrl
  detailPagePath
  ogpImageUrl
  displayShippingWithinYearLastAt
`

export const GetItemGroups = gql`
query($nowOnSale: Boolean, 
      $giftItem: Boolean, 
      $onetimeItem: Boolean, 
      $subscriptionItem: Boolean, 
      $forBusinessItem: Boolean, 
      $postDeliveryItem: Boolean, 
      $saleItem: Boolean, 
      $freeShippingItem: Boolean,
      $withinYearItem: Boolean,
      $freeShippingRegion: String,
      $productionRegion: String,
      $productionPrefecture: String,
      $stockFilter: String, 
      $lowPriceFilter: String, 
      $highPriceFilter: String, 
      $categoryCode: String, 
      $tagCode: String, 
      $adminShopCode: String, 
      $currentPage: Int, 
      $perPage: Int, 
      $orderBy: String, 
      $search: String) {
  itemGroups(nowOnSale: $nowOnSale,
             giftItem: $giftItem,
             onetimeItem: $onetimeItem,
             subscriptionItem: $subscriptionItem, 
             forBusinessItem: $forBusinessItem, 
             postDeliveryItem: $postDeliveryItem, 
             saleItem: $saleItem, 
             freeShippingItem: $freeShippingItem,
             freeShippingRegion: $freeShippingRegion,
             productionRegion: $productionRegion,
             productionPrefecture: $productionPrefecture,
             withinYearItem: $withinYearItem,
             stockFilter: $stockFilter, 
             lowPriceFilter: $lowPriceFilter, 
             highPriceFilter: $highPriceFilter, 
             categoryCode: $categoryCode, 
             tagCode: $tagCode, 
             adminShopCode: $adminShopCode, 
             currentPage: $currentPage, 
             perPage: $perPage, 
             orderBy: $orderBy, 
             search: $search) {
    items {
      id
      name
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}`

export const GetItemGroupsItems = gql`
query($nowOnSale: Boolean, 
      $giftItem: Boolean, 
      $onetimeItem: Boolean, 
      $subscriptionItem: Boolean, 
      $forBusinessItem: Boolean, 
      $postDeliveryItem: Boolean, 
      $saleItem: Boolean, 
      $freeShippingItem: Boolean,
      $withinYearItem: Boolean,
      $freeShippingRegion: String,
      $stockFilter: String, 
      $lowPriceFilter: String, 
      $highPriceFilter: String, 
      $categoryCode: String, 
      $tagCode: String, 
      $adminShopCode: String, 
      $currentPage: Int, 
      $perPage: Int, 
      $orderBy: String, 
      $search: String) {
  itemGroups(nowOnSale: $nowOnSale,
             giftItem: $giftItem,
             onetimeItem: $onetimeItem,
             subscriptionItem: $subscriptionItem, 
             forBusinessItem: $forBusinessItem, 
             postDeliveryItem: $postDeliveryItem, 
             saleItem: $saleItem, 
             freeShippingItem: $freeShippingItem,
             freeShippingRegion: $freeShippingRegion,
             withinYearItem: $withinYearItem,
             stockFilter: $stockFilter, 
             lowPriceFilter: $lowPriceFilter, 
             highPriceFilter: $highPriceFilter, 
             categoryCode: $categoryCode, 
             tagCode: $tagCode, 
             adminShopCode: $adminShopCode, 
             currentPage: $currentPage, 
             perPage: $perPage, 
             orderBy: $orderBy, 
             search: $search) {
    items {
      ${ItemGroupItems}
    }
  }
}`


export const GetNewItemGroups = gql`
query($nowOnSale: Boolean, $stockFilter: String, $currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  newItemGroups(nowOnSale: $nowOnSale, stockFilter: $stockFilter, currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      ${ItemGroupItems}
    }
  }
}`

export const GetTagItemGroups = gql`
query($itemTagId: ID! $nowOnSale: Boolean, $stockFilter: String, $currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  tagItemGroups(itemTagId: $itemTagId, nowOnSale: $nowOnSale, stockFilter: $stockFilter, currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      ${ItemGroupItems}
    }
  }
}`

export const GetRankingItemGroups = gql`
query($nowOnSale: Boolean, $stockFilter: String, $adminShopCode: String, $currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  rankingItemGroups(nowOnSale: $nowOnSale, stockFilter: $stockFilter, adminShopCode: $adminShopCode, currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      ${ItemGroupItems}
    }
  }
}`

export const GetArchiveItemGroups = gql`
query($perPage: Int, $excludeIds: String) {
  archiveItemGroups(perPage: $perPage, excludeIds: $excludeIds) {
    items {
      ${ItemGroupItems}
    }
  }
}`

export const GetArchiveRecommendItemGroups = gql`
query($perPage: Int, $excludeIds: String) {
  archiveRecommendItemGroups(perPage: $perPage, excludeIds: $excludeIds) {
    items {
      ${ItemGroupItems}
    }
  }
}`

export const GetRecommendItemGroups = gql`
query($itemGroupId: ID!) {
  recommendItemGroups(itemGroupId: $itemGroupId) {
    items {
      ${ItemGroupItems}
    }
  }
}`

export const GetItemGroup = gql`
query($id: ID) {
  itemGroup(id: $id) {
    ${ItemGroupItems}
  }
}`

export const GetItemGroupOgpImage = gql`
query($id: ID) {
  itemGroup(id: $id) {
    id
    ogpImageUrl
  }
}`

export const GetItemGroupShow = gql`
query($id: ID, $code: String) {
  itemGroup(id: $id, code: $code) {
    id
    name
    code
    storageType
    showGiftArea
    noshiPossible
    wrappingPossible
    messageCardPossible
    isAlcohol
    notice
    publishedNotice
    reviewNum
    reviewsRatingStarsUrl
    reviewRatingValue
    reviews {
      author
      postedAt
      comment
    }
    adminShop {
      code
      name
      logoImageSet {
        xsSqImage
      }
      prefectureName
      minDeliveryTerm
      maxDeliveryTerm
      delivery {
        fixedMemo
        timerMemo
        shippingWithinYearLastAt
      }
      hasDeliveryTimerMemo
      hasWithinYearMemo
      freeShippingPrice
      isDeliveryFreeShippingOn
      addFeePrefectureList
      qaSupportStatus
    }
    itemGroupImageSets {
      xsSqImage
      mdImage
    }
    firstSelectItem {
      id
      size
      skuName
      properPriceInTax
      salePriceInTax
      sellingPriceInTax
      listImageOrItemGroupListImageUrl
      innerCapacity
      isSale
      displaySaleName
      grantPoint
      pointRate
      capacityRatio
      maxAddCartNum
      soldOut
      isSubscriptionItem
      isNowOnSale
      itemDeliveryMethod {
        id
        isPostDelivery
        isCompactDelivery
      }
      orderMessage
      subscriptionMinDeliveryCount
      subscriptionItemIntervals {
        id
        intervalDays
        intervalName
      }
      displayItemDeliveryDayOfWeeks
      displayShortestDeliveryOn
      displayNextLimitOrderOn
      displayItemFreeShippingRegions
      icons
      shippingRegionPriceList {
        prefectureId
        prefectureName
        price
        priceInTax
        compactPrice
        compactPriceInTax
        postDeliveryPrice
        postDeliveryPriceInTax
      }
    }
    items {
      id
      googleMerchantCenterTitle
      isNowOnSale
      soldOut
      sellingPriceInTax
    }
    noshis {
      id
      name
      isMemoRequired
    }
    wrappings {
      id
      name
      priceInTax
    }
    messageCards {
      id
      name
      priceInTax
    }
    canWritingReview
    itemGroupSameCategories {
      code
      name
    }
    itemGroupImageSet {
      lgImage
    }
  }
}`

export const GetItemGroupDescription = gql`
query($id: ID, $code: String) {
  itemGroup(id: $id, code: $code) {
    id
    name
    adminShop {
      name
      profileImageSet {
        mdSqImage
      }
      prefectureName
      businessContents
      description
    }
    rawMaterials
    innerCapacity
    bestBefore
    preservationMethod
    notice
    publishedNotice
    capacityItems {
      id
      skuName
      innerCapacity
    }
    itemGroupDescriptions {
      contentType
      title
      description
      image
      imageSet {
        mdImage
      }
      descriptionItemGroup {
        name
        code
        isSale
        properPriceInTax
        maxProperPriceInTax
        salePriceInTax
        maxSalePriceInTax
        detailPagePath
        icons
        listImageOrItemGroupImageSet {
          xsSqImage
        }
      }
      pageId
      page {
        title
        firstDescription
        ogImageSet {
          xsSqImage
        }
        itemGroupDescriptionImageSet {
          xsSqImage
        }
        frontUrl
        pageBodies {
          id
          contentType
          description
          image
          alt
          title
          itemGroupId
          itemGroup {
            id
            name
            code
            isSale
            properPriceInTax
            maxProperPriceInTax
            salePriceInTax
            maxSalePriceInTax
            detailPagePath
            icons
            listImageOrItemGroupImageSet {
              xsSqImage
            }
            adminShop {
              name
            }
          }
        }
      }
    }
  }
}
`

export const GetItemGroupExpand = gql`
query($id: ID, $code: String) {
  itemGroup(id: $id, code: $code) {
    id
    code
    name
    itemGroupImageSets {
      mdImage
      xsSqImage
    }
    adminShop {
      code
      name
      logoImageSet {
        xsSqImage
        mdSqImage
      }
      delivery {
        fixedMemo
        timerMemo
        shippingWithinYearLastAt
      }
      hasDeliveryTimerMemo
      hasWithinYearMemo
      prefectureName
      minDeliveryTerm
      maxDeliveryTerm
      isDeliveryFreeShippingOn
      freeShippingPrice
      addFeePrefectureList
      qaSupportStatus
      profileImageSet {
        mdSqImage
      }
      businessContents
      description
    }
    icons
    isAlcohol
    storageType
    publishedNotice
    notice
    canWritingReview
    rawMaterials
    innerCapacity
    capacityItems {
      skuName
      innerCapacity
    }
    bestBefore
		preservationMethod
    itemGroupSameCategories {
      code
      name
    }
    itemGroupImageSet {
      lgImage
    }
    firstSelectItem {
      id
    }
    items {
      id
      size
      skuName
      icons
      listImageOrItemGroupListImageUrl
      isNowOnSale
      soldOut
      isSale
      properPriceInTax
      displaySaleName
      sellingPriceInTax
      itemDeliveryMethod {
        isPostDelivery
        isCompactDelivery
      }
      shippingRegionPriceList {
        prefectureId
        prefectureName
        priceInTax
        postDeliveryPriceInTax
        compactPriceInTax
      }
      grantPoint
      pointRate
      innerCapacity
      capacityRatio
      isSubscriptionItem
      subscriptionItemIntervals {
        intervalDays
        intervalName
      }
      subscriptionMinDeliveryCount
      orderMessage
      maxAddCartNum
      displayItemDeliveryDayOfWeeks
      displayShortestDeliveryOn
      displayNextLimitOrderOn
      googleMerchantCenterTitle
      displayName
      itemGroup {
        id
        name
        adminShop {
          code
          name
        }
      }
    }
    reviewNum
    reviewRatingValue
    reviews {
      author
      displayAuthor
      postedAt
      comment
    }
    reviewsRatingStarsUrl
    showGiftArea
    wrappingPossible
    wrappings {
      id
      name
      priceInTax
    }
    noshiPossible
    noshis {
      id
      name
      isMemoRequired
    }
    messageCardPossible
    messageCards {
      id
      name
      priceInTax
    }
    itemGroupDescriptions {
      contentType
      title
      description
      image
      imageSet {
        mdImage
      }
      descriptionItemGroup {
        name
        code
        isSale
        properPriceInTax
        maxProperPriceInTax
        salePriceInTax
        maxSalePriceInTax
        detailPagePath
        icons
        listImageOrItemGroupImageSet {
          mdSqImage
        }
      }
      pageId
      page {
        title
        firstDescription
        ogImageSet {
          xsSqImage
        }
        itemGroupDescriptionImageSet {
          xsSqImage
        }
        frontUrl
        pageBodies {
          id
          contentType
          description
          image
          alt
          title
          itemGroupId
          itemGroup {
            id
            name
            code
            isSale
            properPriceInTax
            maxProperPriceInTax
            salePriceInTax
            maxSalePriceInTax
            detailPagePath
            icons
            listImageOrItemGroupImageSet {
              xsSqImage
            }
            adminShop {
              name
            }
          }
        }
      }
    }
  }
}
`
export const ItemGroupItemsForApi = `
      id
      code
      name
      detailPagePath
      isNowOnSale
      isNew
      isNormal
      isCold
      isFreeze
      isForceFreeShipping
      isSecondGradeItem
      isNoshiPossible
      isWrappingPossible
      isMessageCardPossible
      publishedNotice
      notice
      isPreSale
      beforePreSale
      nowPreSale
      afterPreSale
      formatPreSaleStartAt
      formatPreSaleEndAt
      formatScheduledShippingStartAt
      listImageOrItemGroupImageSet {
        smSqImage
      }
      adminShop {
        id
        code
        name
        displayShippingWithinYearLastAt
        delivery {
          freeShippingPrice
          freeShippingStatus
        }
      }
      openItems {
        id
        itemGroupId
        size
        publishedStatus
        properPrice
        properPriceInTax
        salePrice
        salePriceInTax
        sellingPrice
        sellingPriceInTax
        isSale
        saleName
        ribbonSaleName
        isNowOnSale
        salableStock
        soldOut
        offSaleEndAt
        formatOffSaleEndAt
        isSubscriptionItem
        isOnetimeItem
        itemFreeShippingRegions {
          id
        }
        itemDeliveryMethod {
          isPostDelivery
          isCompactDelivery
        }
      }
      publishedReviews {
        id
        stars
      }
`

export const GetItemGroupsForApi = gql`
query($nowOnSale: Boolean,
      $giftItem: Boolean,
      $onetimeItem: Boolean,
      $subscriptionItem: Boolean,
      $forBusinessItem: Boolean, 
      $postDeliveryItem: Boolean, 
      $saleItem: Boolean, 
      $freeShippingItem: Boolean,
      $withinYearItem: Boolean,
      $freeShippingRegion: String,
      $productionRegion: String,
      $productionPrefecture: String,
      $stockFilter: String, 
      $storageFilter: String, 
      $lowPriceFilter: String, 
      $highPriceFilter: String, 
      $categoryCode: String, 
      $tagCode: String, 
      $tagId: ID, 
      $searchKeyword: String, 
      $adminShopCode: String, 
      $currentPage: Int, 
      $perPage: Int, 
      $orderBy: String, 
      $search: String) {
  itemGroups(nowOnSale: $nowOnSale,
             giftItem: $giftItem,
             onetimeItem: $onetimeItem,
             subscriptionItem: $subscriptionItem, 
             forBusinessItem: $forBusinessItem, 
             postDeliveryItem: $postDeliveryItem, 
             saleItem: $saleItem, 
             freeShippingItem: $freeShippingItem,
             freeShippingRegion: $freeShippingRegion,
             productionRegion: $productionRegion,
             productionPrefecture: $productionPrefecture,
             withinYearItem: $withinYearItem,
             stockFilter: $stockFilter, 
             storageFilter: $storageFilter, 
             lowPriceFilter: $lowPriceFilter, 
             highPriceFilter: $highPriceFilter, 
             categoryCode: $categoryCode, 
             tagCode: $tagCode, 
             tagId: $tagId, 
             searchKeyword: $searchKeyword, 
             adminShopCode: $adminShopCode, 
             currentPage: $currentPage, 
             perPage: $perPage, 
             orderBy: $orderBy, 
             search: $search) {
    items {
      ${ItemGroupItemsForApi}
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}`


export const GetNewItemGroupsForApi = gql`
query($nowOnSale: Boolean, $stockFilter: String, $currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  newItemGroups(nowOnSale: $nowOnSale, stockFilter: $stockFilter, currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      ${ItemGroupItemsForApi}
    }
  }
}`

export const GetTagItemGroupsForApi = gql`
query($itemTagId: ID! $nowOnSale: Boolean, $stockFilter: String, $currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  tagItemGroups(itemTagId: $itemTagId, nowOnSale: $nowOnSale, stockFilter: $stockFilter, currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      ${ItemGroupItemsForApi}
    }
  }
}`

export const GetSameTagItemGroups = gql`
query($perPage: Int, $itemGroupId: ID!) {
  sameTagItemGroups(perPage: $perPage, itemGroupId: $itemGroupId) {
    items {
      ${ItemGroupItemsForApi}
    }
  }
}`

export const GetArchiveItemGroupsFroApi = gql`
query($currentPage: Int, $perPage: Int, $orderBy: String, $excludeIds: String, ) {
  archiveItemGroups(currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, excludeIds: $excludeIds) {
    items {
      ${ItemGroupItemsForApi}
    }
  }
}`

export const GetItemGroupForApi = gql`
query($id: ID, $code: String, $adminShopCode: String) {
  itemGroup(id: $id, code: $code, adminShopCode: $adminShopCode) {
    id
    code
    name
    rawMaterials
    innerCapacity
    bestBefore
    preservationMethod
    storageType
    publishedNotice
    notice
    isNew
    isForceFreeShipping
    isNormal
    isCold
    isFreeze
    isSecondGradeItem
    isNoshiPossible
    isNowOnSale
    saleStartAt
    dateFormatSaleStartAt
    saleEndAt
    dateFormatSaleEndAt
    isPreSale
    beforePreSale
    nowPreSale
    afterPreSale
    formatPreSaleStartAt
    formatPreSaleEndAt
    formatScheduledShippingStartAt
    publishedNoshis {
      id
      name
      isMemoRequired
    }
    isWrappingPossible
    publishedWrappings {
      id
      name
      priceInTax
    }
    isMessageCardPossible
    publishedMessageCards {
      id
      name
      priceInTax
    }
    isAlcohol
    listImageOrItemGroupImageSet {
      xsSqImage
      mdSqImage
    }
    openItems {
      id
      itemGroupId
      isSale
      size
      skuName
      properPriceInTax
      ribbonSaleName
      sellingPrice
      sellingPriceInTax
      isAddPointItem
      isNowOnSale
      isSubscriptionItem
      isOnetimeItem
      soldOut
      salableStock
      saleStartAt
      dateFormatSaleStartAt
      saleEndAt
      dateFormatSaleEndAt
      innerCapacity
      pointRate
      grantPoint
      capacityRatio
      orderMessage
      maxAddCartNum
      subscriptionMinDeliveryCount
      displayItemDeliveryDayOfWeeks
      displayShortestDeliveryOn
      displayNextLimitOrderOn
      itemImageUrl
      itemImageSets {
        xsSqImage
        mdSqImage
      }
      subscriptionItemIntervals {
        id
        intervalDays
        intervalName
      }
      itemFreeShippingRegions {
        id
        shippingRegion {
          name
        }
      }
      shippingRegionPriceList {
        prefectureId
        prefectureName
        priceInTax
        postDeliveryPriceInTax
        compactPriceInTax
      }
      shippingRegionPriceList {
        prefectureId
        prefectureName
        priceInTax
        postDeliveryPriceInTax
        compactPriceInTax
      }
      itemDeliveryMethod {
        id
        isPostDelivery
        isCompactDelivery
      }
    }
    adminShop {
      id
      name
      code
      pointRate
      prefectureName
      businessContents
      description
      hasDeliveryTimerMemo
      hasWithinYearMemo
      addFeePrefectureList
      qaSupportStatus
      isAddTax
      logoImageSet {
        xsSqImage
      }
      profileImageSet {
        mdSqImage
      }
      delivery {
        freeShippingPrice
        freeShippingStatus
        fixedMemo
        timerMemo
        minDeliveryTerm
        maxDeliveryTerm
        avgShippingTerm
        shippingWithinYearLastAt
      }
    }
    publishedReviews {
      id
      stars
      displayAuthor
      comment
      postedAt
    }
    itemGroupSameCategories {
      id
      code
      name
    }
    itemGroupImageSets {
      lgImage
      mdImage
      xsSqImage
    }
    publishedItemGroupDescriptions {
      contentType
      title
      description
      image
      imageSet {
        mdImage
      }
      descriptionItemGroup {
        name
        code
        isNew
        isForceFreeShipping
        isNormal
        isCold
        isFreeze
        isSecondGradeItem
        isNoshiPossible
        isWrappingPossible
        isMessageCardPossible
        beforePreSale
        afterPreSale
        isNowOnSale
        adminShop {
          delivery {
            freeShippingPrice
            freeShippingStatus
          }
        }
        openItems {
          isSale
          isAddPointItem
          isNowOnSale
          isSubscriptionItem
          isOnetimeItem
          properPriceInTax
          salePriceInTax
          sellingPrice
          saleName
          salableStock
          itemDeliveryMethod {
            id
            isPostDelivery
            isCompactDelivery
          }
          itemFreeShippingRegions {
            id
          }
        }
        detailPagePath
        listImageOrItemGroupImageSet {
          xsSqImage
        }
      }
      pageId
      page {
        title
        firstDescription
        ogImageSet {
          xsSqImage
        }
        itemGroupDescriptionImageSet {
          xsSqImage
        }
        frontUrl
        pageBodies {
          id
          contentType
          description
          image
          alt
          title
          itemGroupId
          itemGroup {
            name
            code
            isNew
            isForceFreeShipping
            isNormal
            isCold
            isFreeze
            isSecondGradeItem
            isNoshiPossible
            isWrappingPossible
            isMessageCardPossible
            adminShop {
              delivery {
                freeShippingPrice
                freeShippingStatus
              }
            }
            openItems {
              isSale
              properPriceInTax
              salePriceInTax
              sellingPrice
              saleName
              itemDeliveryMethod {
                id
                isPostDelivery
                isCompactDelivery
              }
              itemFreeShippingRegions {
                id
              }
    
            }
            detailPagePath
            listImageOrItemGroupImageSet {
              xsSqImage
            }
          }
        }
      }
    }

  }
}`


