import gql from 'graphql-tag'

export const GetQaGroups = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  qaGroups (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {	
    items {
      id
      code
      name
      publishedStatus
      qaItems {
        id
        question
        qaItemBodies {
          id
          contentType
          description
          image
          alt
          title
          itemGroupId
          itemGroup {
            id
            name
            properPriceInTax
            maxProperPriceInTax
            salePriceInTax
            maxSalePriceInTax
            isSale
            listImageUrl
            listImageSet {
              xsImage
            }
            listImageOrItemGroupImageSet {
              xsSqImage
            }
          }
        }
      }
    }
    pageInfo {
      currentPage
      totalCount
      perPage
    }
  }
}`

export const GetQaGroup = gql`
query ($qaGroupId: ID!) {
  qaGroup(qaGroupId: $qaGroupId) {
    id
    publishedStatus
    name
    code
  }
}`
