<template lang="pug">
.faq-list
  h2.title {{ title }}
  slot
</template>

<script>
export default {
  name: "FaqList",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
.faq-list {
  & + .faq-list {
    @include margin-top(xlg);
  }

  > .title {
    font-family: $font-family-serif;
    line-height: $line-height-none;
    color: $black-400;
    font-weight: $font-weight-regular;
    @include font-size(md);
    @include margin-bottom(xxs);
  }

  > .faq-item + .faq-item {
    @include margin-top(xs);
  }
}
</style>