<template lang="pug">
  .qa-index
    h1.heading-1.text-center.mb-sm よくある質問

    template(v-if="hasQaGroupItem")
      FaqList(v-for="qaGroupItem in qaGroupItems" :title="qaGroupItem.name" :key="qaGroupItem.id")
        FaqItem(v-for="qaItem in qaGroupItem.qaItems" :key="qaItem.id" :faq="qaItem")
    Alert(v-else type="info" )
      p Q&Aが登録されていません。

    .text-center.mt-md
      p ご不明な点やご質問がある場合は、お問い合わせフォームをよりお願いいたします。
    .text-center.my-lg
      ButtonBase(href="/contacts/") お問い合わせはこちら

</template>

<script>
  import { GetQaGroups } from "../../graphqls/QaGroups";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import FaqList from "../../components/v1/faq/FaqList";
  import FaqItem from "../../components/v1/faq/FaqItem";
  import Alert from "../../components/v1/alert/AlertBase";
  import ButtonBase from "../../components/v1/button/ButtonBase";


  export default {
    name: "qas-index",
    mixins: [GlobalMixin],
    components: {
      ButtonBase,
      Alert,
      FaqItem,
      FaqList,
    },
    data: function () {
      return {
        qaGroups: {
          items: []
        },
        pageInfo: {
          perPage: -1
        },
        searchForm: {
          adminShopIdEq: "1"
        },
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    computed: {
      qaGroupItems() {
        return this.qaGroups.items
      },
      hasQaGroupItem() {
        return this.qaGroupItems.length > 0
      }
    },
    mounted () {
      this.fetchQas()
      this.showSide()
      this.setBreadcrumbs({ breadcrumbs: [
          {
            path: '/',
            name: 'TOP',
          },
          {
            path: '/qas/',
            name: 'よくある質問',
          },
        ]})
    },
    methods: {
      async fetchQas() {
        this.loadingStart();
        try {
          const { data } = await this.$apollo
          .query({
            query: GetQaGroups,
            variables: { ...this.pageInfo, search: JSON.stringify(this.searchForm) }
          })
          .catch(error => {
            this.loadingStop();
            return;
          });
          this.qaGroups = data.qaGroups;
          this.pageInfo = data.qaGroups.pageInfo
          // this.$scrollTo("body");
          this.loadingStop();
        }
        catch (error) {
          this.loadingStop();
          console.log('e', error)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
